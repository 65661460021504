import React, { useState } from "react";

const OrderItem = ({
  index,
  name,
  quantity,
  stock,
  price,
  updateQuantity,
  decreaseQuantity,
  increaseQuantity,
  stockErr,
}) => {
  const [quan, setQuan] = useState(1);
  console.log(index);
  return (
    <>
      <div className="flex flex-row justify-between items-center py-2 border-b border-gray-300 overflow-y-auto">
        <div className="flex flex-col">
          <span className="ml-1 text-sm">{name}</span>
          <span className="ml-1 text-sm font-semibold">Rs. {price}</span>
        </div>
        <div className="w-32 flex justify-between">
          <button
            className="px-2 rounded bg-gray-50 border border-gray-300"
            onClick={decreaseQuantity}
          >
            -
          </button>
          <input
            type="number"
            className="w-12 rounded bg-gray-50 border border-gray-300 text-center font-semibold"
            value={quantity}
            onChange={(e) => {
              const newQuantity = parseInt(e.target.value);
              setQuan(newQuantity);
              if (
                !isNaN(newQuantity) &&
                newQuantity >= 1 &&
                newQuantity <= stock
              ) {
                updateQuantity(newQuantity);
              }
            }}
          />
          <button
            className="px-2 rounded bg-gray-50 border border-gray-300"
            onClick={increaseQuantity}
          >
            +
          </button>
        </div>
      </div>
      {stockErr.bool && stockErr.id==index  && (
        <div className="">
          <span className="text-xs text-red-500 ">Out of Stock!</span>
        </div>
      )}
    </>
  );
};

export default OrderItem;
