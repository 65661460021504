import React from "react";
import { useParams } from "react-router-dom";
import { useGetSaleDetailQuery } from "../../query/salesQuery";
import { useReactToPrint } from "react-to-print";

const SalesDetail = () => {
  const { id } = useParams();
  const { data } = useGetSaleDetailQuery(id);
  const componentRef = React.useRef();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const {
    customer,
    date,
    bill_no,
    orderitem,
    discount,
    final_amount,
    total_amount,
    payment_method,
  } = data?.data ?? [];
  const convertToText = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const scales = ["", "Thousand", "Million", "Billion"];

    const toWords = (num, scale) => {
      let words = "";
      while (num > 0) {
        if (num % 1000 !== 0) {
          if (words !== "") {
            words =
              convertLessThanOneThousand(num % 1000) +
              " " +
              scales[scale] +
              " " +
              words;
          } else {
            words =
              convertLessThanOneThousand(num % 1000) + " " + scales[scale];
          }
        }
        num = Math.floor(num / 1000);
        scale++;
      }
      return words.trim();
    };

    const convertLessThanOneThousand = (num) => {
      let words = "";
      if (num >= 100) {
        words += units[Math.floor(num / 100)] + " Hundred";
        num %= 100;
      }
      if (num >= 11 && num <= 19) {
        return words + " " + teens[num - 10];
      } else if (num === 10 || num >= 20) {
        words += " " + tens[Math.floor(num / 10)];
        num %= 10;
      }
      if (num >= 1 && num <= 9) {
        words += " " + units[num];
      }
      return words.trim();
    };

    if (num === 0) {
      return "Zero";
    } else {
      return toWords(num, 0);
    }
  };
  return (
    <div className="container mx-auto p-8 mt-14 ">
      <div className="bg-white rounded-lg border border-gray-200">
        <div className="p-8">
          <div className="mb-4">
            <h3 className="text-4xl font-semibold text-center">
              Sampoorna Kitab
            </h3>
            <div className="flex  flex-col items-center">
              <p>Bharatpur-01, Lionschowk, Narayangarh, Chitwan</p>
              <div className="flex gap-4">
                <p className="text-sm">PAN: 608285724</p>
                <p className="text-sm">Phone: 9855065138</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-8">
            <div>
              <h3 className="text-lg font-semibold">Customer Details</h3>
              {customer && (
                <>
                  <p>Name: {customer.name}</p>
                  <p>Phone: {customer.phone}</p>
                  <p>Address: {customer.address}</p>
                </>
              )}
            </div>
            <div>
              <p>Date: {formatDate(date)}</p>
              <p>Bill No: {bill_no && bill_no.replace(/[-_]/g, "")}</p>
            </div>
          </div>

          <h3 className="text-lg font-semibold mb-4">Order Items</h3>
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-2 px-4 bg-gray-100 text-left">
                  Product Name
                </th>
                <th className="py-2 px-4 bg-gray-100 text-left">Quantity</th>
                <th className="py-2 px-4 bg-gray-100 text-left">Price</th>
              </tr>
            </thead>
            <tbody>
              {orderitem?.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{item.product.name}</td>
                  <td className="py-2 px-4 border-b">{item.quantity}</td>
                  <td className="py-2 px-4 border-b">Rs. {item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-col mt-4 items-end justify-start">
            <p>Payment Method: {payment_method}</p>
            <p>Subtotal (Rs.): {total_amount}</p>
            <p>Discount (Rs.): {discount}</p>
            <p>Final Amount (Rs.): {final_amount ? final_amount.toFixed(2) : final_amount}</p>
            <p>Amount in Words: {convertToText(final_amount)} only</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDetail;
