import React from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

const CustomerTable = ({ columns, data }) => {
  return (
    <table className="w-full border-collapse">
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.Header}
              className="py-2 px-4 bg-gray-100 text-gray-600 text-left"
            >
              {column.Header}
            </th>
          ))}
          <th className="py-2 px-4 bg-gray-100 text-gray-600 text-left">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex} className="hover:shadow cursor-pointer">
            {columns?.map((column, columnIndex) => (
              <td
                key={columnIndex}
                className="py-3 px-4 border-b border-gray-200 text-gray-600 text-sm"
              >
                {/* Make the "Name" column clickable */}
                {column.accessor === "name" ? (
                  <Link to={`/customer/${row.id}`}>{row[column.accessor]}</Link>
                ) : (
                  row[column.accessor]
                )}
              </td>
            ))}
            <td className="py-3 px-4 border-b border-gray-200 text-gray-600 text-sm">
              <div className="flex gap-2">
                <Link to={`/customer/${row.id}`}>
                  <button className="text-gray-500 border p-1 border-gray-400 rounded text-center">
                    <FaEye />
                  </button>
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomerTable;
