import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = ({ children, open }) => {
  return (
    <>
      <div className="flex flex-auto h-screen">
        <Sidebar open={open} />
        <div className="grow ml-2 md:ml-60">
          <Navbar />
          <div className="m-5">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
