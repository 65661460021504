import { useMutation, useQuery, useQueryClient } from "react-query";
import { changePass, getInfo } from "../services/change";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


export const useGetInfoQuery = (pageno) => {
    return useQuery("change", () => getInfo(pageno,), {
      onError: (err) => {
        toast.error("Error Getting Books!");
      },
    });
  };

  export const useChangePassQuery = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const mutaion = useMutation({
      mutationFn: (payload) => {
        return changePass(payload.id, payload.formdata);
      },
      onError: (err) => {
        toast.error("Error while changing password");
      },
      onSuccess: (suc) => {
        toast.success("Password Changed Sucessfully!");
        queryClient.invalidateQueries("change");
        // navigate('/')
        window.location.href = '/'
        localStorage.removeItem('authTo')
      },
    });
    return mutaion;
  };