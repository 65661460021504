import React from "react";
import { useParams } from "react-router-dom";
import { useGetCustomerDetailQuery } from "../../query/customerQuery";

const CustomerDetailPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCustomerDetailQuery(id);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const customer = data?.data ?? {};

  return (
    <div className="mt-20 border border-gray-200 rounded-lg p-4 ">
      <h2 className="text-2xl font-bold mb-2 text-slate-700">
        {customer.name}
      </h2>
      <p className="text-gray-600 mb-2">Phone: {customer.phone}</p>
      <p className="text-gray-600 mb-4">Address: {customer.address}</p>

      <h3 className="text-lg font-bold mb-2 text-slate-700">Orders:</h3>
      {customer?.order?.length ? (
        <table className="table-auto w-full text-left">
          <thead className="bg-gray-100 text-slate-700">
            <tr>
              <th className="px-4 py-2">Bill No</th>
              <th className="px-4 py-2">Total Amount</th>
              <th className="px-4 py-2">Discount</th>
              <th className="px-4 py-2">Final Amount</th>
            </tr>
          </thead>
          <tbody>
            {customer.order.map((order) => (
              <tr key={order.id} className="hover:shadow">
                <td className="border-t px-4 py-3">
                  {order.bill_no.replace(/[-_]/g, "")}
                </td>
                <td className="border-t px-4 py-3">{order.total_amount}</td>
                <td className="border-t px-4 py-3">{order.discount.toFixed(2)}</td>
                <td className="border-t px-4 py-3">
                  {order.final_amount.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders available.</p>
      )}
    </div>
  );
};

export default CustomerDetailPage;
