import React, { useState } from "react";
import { useChangePassQuery, useGetInfoQuery } from "../../query/changeQuery";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const data = useGetInfoQuery();
  const { mutate } = useChangePassQuery();
  const userId = data?.data?.data?.id;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("old_password", currentPassword);
    formdata.append("password", newPassword);
    formdata.append("password2", confirmPassword);
    const payload = { id: userId, formdata };
    mutate(payload);
    // setCurrentPassword("");
    // setNewPassword("");
    // setConfirmPassword("");
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container  mx-auto p-6 w-[400px] mt-20 shadow-md rounded-lg items-center justify-center">
      <h2 className="text-xl font-bold mb-4">Change Password</h2>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="relative">
          <input
            type={showCurrentPassword ? "text" : "password"}
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="p-2 border border-gray-300 w-full rounded"
          />
          {currentPassword && (
            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none"
              onClick={() => togglePasswordVisibility("current")}
            >
              {showCurrentPassword ? (
                <FaEyeSlash className="text-slate-700" />
              ) : (
                <FaEye className="text-slate-700" />
              )}
            </button>
          )}
        </div>
        <div className="relative">
          <input
            type={showNewPassword ? "text" : "password"}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="p-2 border w-full border-gray-300 rounded"
          />
          {newPassword && (
            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none"
              onClick={() => togglePasswordVisibility("new")}
            >
              {showNewPassword ? (
                <FaEyeSlash className="text-slate-700" />
              ) : (
                <FaEye className="text-slate-700" />
              )}
            </button>
          )}
        </div>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="p-2 border w-full border-gray-300 rounded"
          />
          {confirmPassword && (
            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none"
              onClick={() => togglePasswordVisibility("confirm")}
            >
              {showConfirmPassword ? (
                <FaEyeSlash className="text-slate-700" />
              ) : (
                <FaEye className="text-slate-700" />
              )}
            </button>
          )}
        </div>
        <button
          type="submit"
          className="bg-slate-700 text-white px-4 py-2 rounded hover:bg-slate-800"
        >
          Change Password
        </button>
      </form>
      {message && <p className="mt-4 text-green-500">{message}</p>}
    </div>
  );
};

export default ChangePassword;
