import React from "react";

const Footer = () => {
  return (
    <>
      <nav className="mx-2  py-2.5 rounded  bottom-0 left-0 right-0 z-50">
        <div className="container flex justify-between items-center mx-auto pt-3">
          <div className="flex items-center mx-auto">
            <span className="text-sm text-gray-500 font-medium whitespace-nowrap ">
              © 2023 Sampoorna Kitab | Designed by{" "}
              <a href="https://acetechnepal.com" target="_blank">
                AceTech
              </a>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Footer;
