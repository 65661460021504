import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { FaSignOutAlt, FaUser, FaUnlockAlt } from "react-icons/fa";
import logo from "../assets/logo.jpeg";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [time, setTime] = useState(new Date());
  const { logoutUser } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setIsModalOpen(true);
  };

  const handleChangePassword = () => {
    navigate('/changepass')
    setIsDropdownOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000);
  }, []);

  return (
    <>
      <nav className="bg-gray-50 border-gray-200 fixed top-0 left-0 right-0 max-h-[80px] pb-2">
        <div className="container flex justify-between items-center mx-auto pt-3">

          <div className="flex items-center mx-auto">
            <span className="text-xl font-medium whitespace-nowrap ">
              {time.toLocaleTimeString()}
            </span>
          </div>
          <div className="relative">
            <div
              onClick={toggleDropdown}
              className="flex items-center gap-2 cursor-pointer text-slate-700"
            >
              <CgProfile size={26} /> Profile
            </div>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-44 text-sm text-slate-700 bg-white border border-gray-200 rounded shadow">
                <div
                  className="p-3 flex items-center cursor-pointer hover:bg-gray-100"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </div>
                <div
                  className="p-3 flex items-center cursor-pointer hover:bg-gray-100 "
                  onClick={handleChangePassword}
                >
                  <FaUnlockAlt className="mr-2" /> Change Password
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      {isModalOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded w-96">
            <h2 className="text-xl font-medium mb-8 text-center">
              Are you sure want to Logout?
            </h2>
            <div className="flex justify-center">
              <button
                type="button"
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-3 rounded mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-red-700 hover:bg-red-600 text-white py-1 px-3 rounded"
                onClick={() => {
                  logoutUser();
                  closeModal();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
