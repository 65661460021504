import React from "react";

const InvoiceModal = ({
  componentRef,
  name,
  phone,
  bill_no,
  address,
  orderitem,
  subtotal,
  total,
  discount,
  closeAddModal,
  handlePrint,
}) => {
  const convertToText = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const scales = ["", "Thousand", "Million", "Billion"];

    const toWords = (num, scale) => {
      let words = "";
      while (num > 0) {
        if (num % 1000 !== 0) {
          if (words !== "") {
            words =
              convertLessThanOneThousand(num % 1000) +
              " " +
              scales[scale] +
              " " +
              words;
          } else {
            words =
              convertLessThanOneThousand(num % 1000) + " " + scales[scale];
          }
        }
        num = Math.floor(num / 1000);
        scale++;
      }
      return words.trim();
    };

    const convertLessThanOneThousand = (num) => {
      let words = "";
      if (num >= 100) {
        words += units[Math.floor(num / 100)] + " Hundred";
        num %= 100;
      }
      if (num >= 11 && num <= 19) {
        return words + " " + teens[num - 10];
      } else if (num === 10 || num >= 20) {
        words += " " + tens[Math.floor(num / 10)];
        num %= 10;
      }
      if (num >= 1 && num <= 9) {
        words += " " + units[num];
      }
      return words.trim();
    };

    if (num === 0) {
      return "Zero";
    } else {
      return toWords(num, 0);
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 overflow-y-auto">
      <div className="container mx-auto p-8 w-1/2">
        <div className="bg-white rounded-lg shadow-lg">
          <div className="h-[90vh] overflow-y-auto">
            <div className="p-8" ref={componentRef}>
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-center">
                  Sampoorna Kitab
                </h3>
                <div className="flex flex-col items-center">
                  <p className="text-sm">
                    Bharatpur-01, Lionschowk, Narayangarh, Chitwan
                  </p>
                  <div className="flex gap-4">
                    <p className="text-sm">PAN: 608285724</p>
                    <p className="text-sm">Phone: 9855065138</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mb-8">
                <div>
                  <h3 className="text-md font-semibold ">Customer Details</h3>
                  <>
                    <p className="text-sm">Name: {name}</p>
                    <p className="text-sm">Phone: {phone}</p>
                    <p className="text-sm">Address: {address}</p>
                  </>
                </div>
                <div>
                  <p>Bill No: {bill_no && bill_no.replace(/[-_]/g, "")}</p>
                  <p className="text-sm">
                    Date: {new Date().toLocaleDateString()}
                  </p>
                </div>
              </div>
              <table className="w-full ">
                <thead>
                  <tr className="border-b border-dashed border-gray-500 text-left">
                    <th className="py-2 px-4 text-sm text-left">Book</th>
                    <th className="py-2 px-4 text-sm text-left">Quantity</th>
                    <th className="py-2 px-4 text-sm text-left">Price</th>
                    <th className="py-2 px-4 text-sm text-left">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderitem?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-dashed border-gray-500 text-left text-sm"
                    >
                      <td className="py-1 px-4 ">{item.name}</td>
                      <td className="py-1 px-4 ">{item.quantity}</td>
                      <td className="py-1 px-4 ">Rs. {item.price}</td>
                      <td className="py-1 px-4">
                        Rs. {item.price * item.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex flex-col mt-4 items-end justify-start">
                <p>Subtotal (Rs.): {subtotal.toFixed(2)}</p>
                <p>Discount (Rs.): {discount.toFixed(2)}</p>
                <p>Final Amount (Rs.): {total.toFixed(2)}</p>
                <p>Amount in Words: {convertToText(total)} only</p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 px-6 py-4">
            <button
              type="button"
              className="bg-gray-300 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-md"
              onClick={closeAddModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-slate-700 hover:bg-slate-600 text-gray-100 py-2 px-4 rounded-md"
              onClick={() => {
                handlePrint();
                closeAddModal();
              }}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvoiceModal;
